import { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// auth
import ReactGA from 'react-ga4';
import { useAuthContext, GuestGuard, AuthGuard } from '@/auth';
// layouts
import { DashboardLayout } from '@/layouts/dashboard';
import { GOOGLE_ANALYTICS } from '@/config';
import CompactLayout from '../layouts/compact';
// import DashboardLayout from '../layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from '../config';
//
import {
  Page404,
  Landing,
  Dashboard,
  PerformanceReport,
  Users,
  CreateUser,
  EditUser,
  Math,
  PerformanceReportChart,
  PerformanceReportByStudent,
  AcademyCourses,
  AcademyCourseChapters,
  LearnerCourseDetail,
  LearnerCourseReport,
  LearnerCourseReview,
  LearnerCourseAssessment,
  MyDoubts,
  MyReviseList,
  IIPUCExamInfo,
  ContributorQuestions,
  ContributorCreateQuestion,
  ContributorTopics,
  ContributorTSRs,
  ContributorCreateEditTSR,
  Roles,
  EditRole,
  CET,
  MyGoals,
  ContributorQuestionSearch,
} from './elements';

// ----------------------------------------------------------------------

import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.initialize(GOOGLE_ANALYTICS);
    }
  }, []);

  useEffect(() => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location]);

  const publicRoutes = [
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   // element: <Navigate to="/login" replace />,
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { element: <HomePage />, index: true },
    //   ],
    // },
    // {
    //   path: '/login',
    //   element: (
    //     <GuestGuard>
    //       <LoginPage />
    //     </GuestGuard>
    //   ),
    // },
    {
      path: '/',
      element: <Landing />,
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Landing />
        </GuestGuard>
      ),
    },
    {
      path: '/cet',
      element: (
        <GuestGuard>
          <CET />
        </GuestGuard>
      ),
    },
    {
      element: <CompactLayout />,
      children: [{ path: '*', element: <Page404 /> }],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const protectedRoutes = [
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.home} replace />, index: true },
        { path: 'home', element: <Dashboard />, index: true },

        {
          path: '/info/ii-puc-board-exam',
          element: <IIPUCExamInfo />,
        },

        // ADMIN
        {
          path: 'admin',
          children: [
            // USERS
            {
              path: 'users',
              element: (
                <AuthGuard permission="Administration.Users">
                  <Users />
                </AuthGuard>
              ),
            },
            {
              path: 'users/new',
              element: (
                <AuthGuard permission="Administration.Users.Create">
                  <CreateUser />
                </AuthGuard>
              ),
            },
            {
              path: 'users/edit/:id',
              element: (
                <AuthGuard permission="Administration.Users.Edit">
                  <EditUser />
                </AuthGuard>
              ),
            },
            // ROLES
            {
              path: 'roles',
              element: (
                <AuthGuard permission="Administration.Roles">
                  <Roles />
                </AuthGuard>
              ),
            },
            {
              path: 'roles/edit/:id',
              element: (
                <AuthGuard permission="Administration.Roles.Edit">
                  <EditRole />
                </AuthGuard>
              ),
            },

            // MATH
            {
              path: 'math',
              element: (
                <AuthGuard permission="Administration">
                  <Math />
                </AuthGuard>
              ),
            },
          ],
        },

        //ACADEMY
        {
          path: 'academy',
          children: [
            {
              path: 'pr',
              element: (
                <AuthGuard permission="Academy.Admin">
                  <PerformanceReport />
                </AuthGuard>
              ),
            },
            {
              path: 'pr/:id',
              element: (
                <AuthGuard permission="Academy.Admin">
                  <PerformanceReportChart />
                </AuthGuard>
              ),
            },
            {
              path: 'pr/bs/:id',
              element: (
                <AuthGuard permission="Academy.Admin">
                  <PerformanceReportByStudent />
                </AuthGuard>
              ),
            },
            {
              path: 'subjects',
              element: (
                <AuthGuard permission="Academy.Admin">
                  <AcademyCourses />
                </AuthGuard>
              ),
            },
            {
              path: 'chapters',
              element: (
                <AuthGuard permission="Academy.Admin">
                  <AcademyCourseChapters />
                </AuthGuard>
              ),
            },
          ],
        },

        // LEARNER
        {
          path: 'learner',
          children: [
            {
              path: 'mydoubts',
              element: (
                <AuthGuard permission="Learner">
                  <MyDoubts />
                </AuthGuard>
              ),
            },
            {
              path: 'myreviselist',
              element: (
                <AuthGuard permission="Learner">
                  <MyReviseList />
                </AuthGuard>
              ),
            },
            {
              path: 'course/eval/:id',
              element: (
                <AuthGuard permission="Learner">
                  <LearnerCourseAssessment />
                </AuthGuard>
              ),
            },
            {
              path: 'course/:id',
              element: (
                <AuthGuard permission="Learner">
                  <LearnerCourseDetail />
                </AuthGuard>
              ),
            },
            {
              path: 'course/report/:id',
              element: (
                <AuthGuard permission="Learner">
                  <LearnerCourseReport />
                </AuthGuard>
              ),
            },
            {
              path: 'course/review/:id',
              element: (
                <AuthGuard permission="Learner">
                  <LearnerCourseReview />
                </AuthGuard>
              ),
            },
          ],
        },

        // CET
        {
          path: 'cet',
          children: [
            {
              path: 'mygoals',
              element: (
                <AuthGuard permission="Learner.CET">
                  <MyGoals />
                </AuthGuard>
              ),
            },
          ],
        },

        // CONTRIBUTOR
        {
          path: 'contributor',
          children: [
            {
              path: 'questions',
              element: (
                <AuthGuard permission="Contributor">
                  <ContributorQuestions />
                </AuthGuard>
              ),
            },
            {
              path: 'questions/:tid/create',
              element: (
                <AuthGuard permission="Contributor">
                  <ContributorCreateQuestion />
                </AuthGuard>
              ),
            },
            {
              path: 'questions/edit/:id',
              element: (
                <AuthGuard permission="Contributor">
                  <ContributorCreateQuestion />
                </AuthGuard>
              ),
            },
            {
              path: 'questions/search',
              element: (
                <AuthGuard permission="Contributor">
                  <ContributorQuestionSearch />
                </AuthGuard>
              ),
            },
            {
              path: 'topics',
              element: (
                <AuthGuard permission="Contributor.Topics">
                  <ContributorTopics />
                </AuthGuard>
              ),
            },
            {
              path: 'tsr',
              element: (
                <AuthGuard permission="Contributor.TSR">
                  <ContributorTSRs />
                </AuthGuard>
              ),
            },
            {
              path: 'tsr/create',
              element: (
                <AuthGuard permission="Contributor.TSR.Create">
                  <ContributorCreateEditTSR />
                </AuthGuard>
              ),
            },
            {
              path: 'tsr/edit/:id',
              element: (
                <AuthGuard permission="Contributor.TSR.Edit">
                  <ContributorCreateEditTSR />
                </AuthGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '*', element: <Page404 /> }],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const routes = isAuthenticated ? protectedRoutes : publicRoutes;

  return useRoutes(routes);
}
