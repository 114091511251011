import { Link, Typography, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LogoProps } from './Logo';

// eslint-disable-next-line react/display-name
const LogoText = forwardRef<HTMLDivElement, LogoProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const text = (
      <Typography component="h1" variant="h5" sx={{ fontFamily: 'monospace' }}>
        CATEMP
      </Typography>
    );

    if (disabledLink) {
      return <>{text}</>;
    }

    return (
      <Link
        to="/"
        component={RouterLink}
        sx={{ display: 'contents' }}
        color={theme.palette.error.dark}
      >
        {text}
      </Link>
    );
  }
);

export default LogoText;
