// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  // root: ROOTS_AUTH,
  login: '/login',
  register: '/register',
  verify: '/verify',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
};

export const PATH_PUBLIC = {
  iiPUCBoardExam: '/info/ii-puc-board-exam',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, 'home'),
  courses: path(ROOTS_DASHBOARD, 'courses'),
  admin: {
    users: {
      root: path(ROOTS_DASHBOARD, 'admin/users'),
      new: path(ROOTS_DASHBOARD, 'admin/users/new'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `admin/users/edit/${id}`),
    },
    roles: {
      root: path(ROOTS_DASHBOARD, 'admin/roles'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `admin/roles/edit/${id}`),
    },
  },
  academy: {
    root: path(ROOTS_DASHBOARD, 'academy'),
    performanceReport: path(ROOTS_DASHBOARD, 'academy/pr'),
    performanceReportChart: (id: number) => path(ROOTS_DASHBOARD, `academy/pr/${id}`),
    performanceReportByStudent: (id: number) => path(ROOTS_DASHBOARD, `academy/pr/bs/${id}`),
    subjects: path(ROOTS_DASHBOARD, 'academy/subjects'),
    chapters: path(ROOTS_DASHBOARD, 'academy/chapters'),
  },
  learner: {
    root: path(ROOTS_DASHBOARD, 'learner'),
    myDoubts: path(ROOTS_DASHBOARD, 'learner/mydoubts'),
    myReviseList: path(ROOTS_DASHBOARD, 'learner/myreviselist'),
    course: (id: string) => path(ROOTS_DASHBOARD, `learner/course/${id}`),
    courseReport: (id: number) => path(ROOTS_DASHBOARD, `learner/course/report/${id}`),
    courseReview: (id: number) => path(ROOTS_DASHBOARD, `learner/course/review/${id}`),
    courseAssessment: (id: number) => path(ROOTS_DASHBOARD, `learner/course/eval/${id}`),
  },
  cet: {
    root: path(ROOTS_DASHBOARD, 'cet'),
    myGoals: path(ROOTS_DASHBOARD, 'cet/mygoals'),
  },
  contributor: {
    root: path(ROOTS_DASHBOARD, 'contributor'),
    questions: path(ROOTS_DASHBOARD, 'contributor/questions'),
    createQuestion: (id: number) => path(ROOTS_DASHBOARD, `contributor/questions/${id}/create`),
    editQuestion: (id: number) => path(ROOTS_DASHBOARD, `contributor/questions/edit/${id}`),
    questionSearch: path(ROOTS_DASHBOARD, 'contributor/questions/search'),
    topics: path(ROOTS_DASHBOARD, 'contributor/topics'),
    tsr: path(ROOTS_DASHBOARD, 'contributor/tsr'),
    createTsr: path(ROOTS_DASHBOARD, 'contributor/tsr/create'),
    editTsr: (id: number) => path(ROOTS_DASHBOARD, `contributor/tsr/edit/${id}`),
  },
};
