// routes
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
// import SvgColor from '../../../components/svg-color';
// ICONS
import { BsQuestionSquareFill } from 'react-icons/bs';
import {
  MdTopic,
  MdHome,
  MdPeopleAlt,
  MdOutlinePeople,
  MdQuiz,
  MdBookmarks,
  MdAssignment,
} from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { GiTargetShot } from 'react-icons/gi';
import { PATH_DASHBOARD } from '@/routes/paths';
import { NavSection } from './types';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// const icon = (name: string) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   blog: icon('ic_blog'),
//   cart: icon('ic_cart'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };

const navConfig: NavSection[] = [
  {
    subheader: 'General',
    permission: 'Dashboard',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.home,
        icon: <MdHome size={24} />,
        permission: 'Dashboard',
      },
    ],
  },
  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Administration',
    permission: 'Administration',
    items: [
      {
        title: 'Users',
        path: PATH_DASHBOARD.admin.users.root,
        icon: <MdPeopleAlt size={24} />,
        permission: 'Administration.Users',
      },
      {
        title: 'Roles',
        path: PATH_DASHBOARD.admin.roles.root,
        icon: <MdOutlinePeople size={24} />,
        permission: 'Administration.Roles',
      },
    ],
  },

  // LEARNER
  // ----------------------------------------------------------------------
  {
    subheader: 'Learnings',
    permission: 'Learner',
    items: [
      {
        title: 'MyDoubts',
        path: PATH_DASHBOARD.learner.myDoubts,
        icon: <MdQuiz size={24} />,
        permission: 'Learner',
      },
      {
        title: 'MyReviseList',
        path: PATH_DASHBOARD.learner.myReviseList,
        icon: <MdBookmarks size={24} />,
        permission: 'Learner',
      },
    ],
  },

  // CET
  // ----------------------------------------------------------------------
  {
    subheader: 'CET',
    permission: 'Learner.CET',
    items: [
      {
        title: 'MyGoals',
        path: PATH_DASHBOARD.cet.myGoals,
        icon: <GiTargetShot size={24} />,
        permission: 'Learner.CET',
      },
    ],
  },

  // SCHOOL ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Academy',
    permission: 'Academy',
    items: [
      {
        title: 'PerformanceReport',
        path: PATH_DASHBOARD.academy.performanceReport,
        icon: <MdAssignment size={24} />,
        permission: 'Academy.Admin',
      },
    ],
  },

  // PROFESSOR
  // ----------------------------------------------------------------------

  // CONTRIBUTER
  // ----------------------------------------------------------------------
  {
    subheader: 'Contributor',
    permission: 'Contributor',
    items: [
      {
        title: 'Questions',
        path: PATH_DASHBOARD.contributor.questions,
        icon: <BsQuestionSquareFill size={24} />,
        permission: 'Contributor',
      },
      {
        title: 'Topics',
        path: PATH_DASHBOARD.contributor.topics,
        icon: <MdTopic size={24} />,
        permission: 'Contributor.Topics',
      },
      {
        title: 'TSR',
        path: PATH_DASHBOARD.contributor.tsr,
        icon: <HiOutlineDocumentReport size={24} />,
        permission: 'Contributor.TSR',
      },
    ],
  },
];

export default navConfig;
