import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '@/components/loading-screen';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH

export const CET = Loadable(lazy(() => import('@/pages/auth/CET')));

// MAIN
export const Dashboard = Loadable(lazy(() => import('@/pages/Dashboard')));
export const Landing = Loadable(lazy(() => import('@/pages/Landing')));

export const Users = Loadable(lazy(() => import('@/pages/admin/users/Users')));
export const CreateUser = Loadable(lazy(() => import('@/pages/admin/users/CreateUser')));
export const EditUser = Loadable(lazy(() => import('@/pages/admin/users/EditUser')));
export const Roles = Loadable(lazy(() => import('@/pages/admin/roles/Roles')));
export const EditRole = Loadable(lazy(() => import('@/pages/admin/roles/EditRole')));

export const Math = Loadable(lazy(() => import('@/pages/admin/math/Math')));

// ACADEMY
export const PerformanceReport = Loadable(lazy(() => import('@/pages/academy/PerformanceReport')));
export const PerformanceReportChart = Loadable(
  lazy(() => import('@/pages/academy/PerformanceReportChart'))
);
export const PerformanceReportByStudent = Loadable(
  lazy(() => import('@/pages/academy/PerformanceReportByStudent'))
);
export const AcademyCourses = Loadable(lazy(() => import('@/pages/academy/AcademyCourses')));
export const AcademyCourseChapters = Loadable(
  lazy(() => import('@/pages/academy/AcademyCourseChapters'))
);

// LEARNER
export const LearnerCourseDetail = Loadable(
  lazy(() => import('@/pages/learner/LearnerCourseDetail'))
);
export const LearnerCourseReport = Loadable(
  lazy(() => import('@/pages/learner/LearnerCourseReport'))
);
export const LearnerCourseReview = Loadable(
  lazy(() => import('@/pages/learner/LearnerCourseReview'))
);
export const LearnerCourseAssessment = Loadable(
  lazy(() => import('@/pages/learner/LearnerCourseAssessment'))
);
export const MyDoubts = Loadable(lazy(() => import('@/pages/learner/MyDoubts')));
export const MyReviseList = Loadable(lazy(() => import('@/pages/learner/MyReviseList')));
export const IIPUCExamInfo = Loadable(lazy(() => import('@/pages/learner/IIPUCExamInfo')));

// CET LEARNER
export const MyGoals = Loadable(lazy(() => import('@/pages/learner/cet/MyGoals')));

// CONTRIBUTOR
export const ContributorQuestions = Loadable(
  lazy(() => import('@/pages/contributor/questions/ContributorQuestions'))
);
export const ContributorQuestionSearch = Loadable(
  lazy(() => import('@/pages/contributor/questions/ContributorQuestionSearch'))
);
export const ContributorCreateQuestion = Loadable(
  lazy(() => import('@/pages/contributor/questions/ContributorCreateQuestion'))
);
export const ContributorTopics = Loadable(
  lazy(() => import('@/pages/contributor/ContributorTopics'))
);
export const ContributorTSRs = Loadable(
  lazy(() => import('@/pages/contributor/tsr/ContributorTSRs'))
);
export const ContributorCreateEditTSR = Loadable(
  lazy(() => import('@/pages/contributor/tsr/ContributorCreateEditTSR'))
);

export const Courses = Loadable(lazy(() => import('@/pages/Courses')));
export const Academy = Loadable(lazy(() => import('@/pages/Academy')));

export const Page404 = Loadable(lazy(() => import('@/pages/Page404')));
